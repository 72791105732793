import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React from 'react'

type ItemGridProps = { children: React.ReactNode }

export function ItemGrid({ children }: ItemGridProps): React.ReactElement {
  return (
    <ul
      css={css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        grid-gap: 2rem;
        align-items: stretch;
      `}
    >
      {children}
    </ul>
  )
}

type ItemContainerProps = {
  image: React.ReactElement
  name: string
  linkTo: string
}

export function ItemContainer({
  linkTo,
  image,
  name,
}: ItemContainerProps): React.ReactElement {
  return (
    <li className="rounded-lg border border-gray-300 shadow-lg overflow-hidden">
      <Link
        to={linkTo}
        className="h-full flex flex-col justify-between bg-white"
      >
        <div className="p-8 my-auto">{image}</div>

        <p className="bg-gray-100 text-center px-4 pb-3 pt-2 border-t border-gray-300 bg-indigo-100">
          {name}
        </p>
      </Link>
    </li>
  )
}
