import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React from 'react'
import { cx } from '../utils/helpers'
import { FeatherIcon } from './FeatherIcon'
import { WidthWrapper } from './WidthWrapper'

export const Breadcrumb: React.FC = ({ children }) => {
  return (
    <nav
      aria-label="breadcrumb"
      // z-index is to set for top-level stacking order (so that child zIndices don’t interfere with other top-level elements)
      className="relative z-breadcrumb my-8"
    >
      <WidthWrapper>
        <ol
          className={cx(
            'max-w-full inline-flex rounded shadow-md bg-white',
            'overflow-x-auto overflow-y-hidden scrolling-touch',
          )}
        >
          <BreadcrumbItem href="/">
            <FeatherIcon
              name="home"
              size={16}
              className="text-gray-700"
              aria-label="Home"
            />
          </BreadcrumbItem>
          {children}
        </ol>
      </WidthWrapper>
    </nav>
  )
}

type BreadcrumbItemProps = {
  href?: string
}

const maxBreadcrumbItems = 10
const descZIndices = Array(maxBreadcrumbItems)
  .fill('')
  .map(
    (_, index) => css`
      :nth-of-type(${index + 1}) {
        z-index: ${maxBreadcrumbItems - index};
      }
    `,
  )

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  href,
  children,
}) => {
  return (
    <li
      className="whitespace-no-wrap text-sm"
      aria-current={!href ? 'page' : undefined}
      css={css`
        line-height: 36px;

        ${descZIndices};

        /* first item has normal left padding */
        :first-of-type > * {
          padding-left: var(--space-4);
        }

        /* last item has normal right padding */
        :last-of-type > * {
          padding-right: var(--space-4);
        }

        /* every item but first has larger left padding, to account for triangle */
        :not(:first-of-type) > * {
          padding-left: var(--space-8);
        }

        /* every item but last has smaller right padding, and a triangle */
        :not(:last-of-type) > * {
          padding-right: var(--space-1);

          /* the triangle */
          ::after {
            content: '';
            display: block;
            position: absolute;
            box-shadow: var(--shadow-md);
            border-radius: var(--rounded);

            /* stack it below the text, helps with box-shadow */
            z-index: -1;

            /* negative top to account for border-radius */
            top: -2px;
            right: 0;

            /* slightly larger than line-height, to account for border-radius */
            width: 40px;
            height: 40px;

            /* 0.707 is pythagoras sqrt(0.707^2 + 0.707^2) = 1 */
            transform: rotate(-45deg) scale(0.707) translate(50%, 50%);

            background: inherit;
          }
        }
      `}
    >
      {href ? (
        <Link
          to={href}
          className="relative h-full flex items-center bg-white hover:bg-gray-100"
        >
          {children}
        </Link>
      ) : (
        <div>{children}</div>
      )}
    </li>
  )
}
