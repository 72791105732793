import React, { ReactElement } from 'react'
import Helmet from 'react-helmet'
import { useFallbackSeoDescription } from './company-info/useFallbackSeoDescription'
import { useTitleSuffix } from './company-info/useTitleSuffix'

type Props = {
  title: string
  metaDescription: string
}

export function SEO({ title = '', metaDescription }: Props): ReactElement {
  const titleSuffix = useTitleSuffix()
  const fallbackSeoDescription = useFallbackSeoDescription()

  const combinedTitle = title + titleSuffix

  return (
    <Helmet>
      <title>{combinedTitle}</title>

      {/* General tags */}
      <meta
        property="og:description"
        name="description"
        content={metaDescription + ' ' + fallbackSeoDescription}
      />
      {/* <meta name="image" content={image} /> */}

      {/* Schema.org tags */}
      {/* <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script> */}

      {/* OpenGraph tags */}
      {/* <meta property="og:url" content={postSEO ? postURL : blogURL} /> */}
      {/* {postSEO ? <meta property="og:type" content="article" /> : null} */}
      <meta property="og:title" content={combinedTitle} />
      {/* <meta property="og:image" content={image} /> */}

      {/* Twitter Card tags */}
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      {/* <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ''} /> */}
      {/* <meta name="twitter:title" content={title} /> */}
      {/* <meta name="twitter:description" content={description} /> */}
      {/* <meta name="twitter:image" content={image} /> */}
    </Helmet>
  )
}
