import { graphql, useStaticQuery } from 'gatsby'
import { FallbackSeoDescriptionQuery } from '../../types/graphql'

export function useFallbackSeoDescription() {
  const data = useStaticQuery<FallbackSeoDescriptionQuery>(query)
  const fallbackSeoDescription = data.datoCmsSite!.globalSeo!.fallbackSeo!
    .description!

  return fallbackSeoDescription
}

const query = graphql`
  query FallbackSeoDescriptionQuery {
    datoCmsSite {
      globalSeo {
        fallbackSeo {
          description
        }
      }
    }
  }
`
