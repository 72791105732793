import { graphql, useStaticQuery } from 'gatsby'
import { TitleSuffixQuery } from '../../types/graphql'

export function useTitleSuffix() {
  const data = useStaticQuery<TitleSuffixQuery>(query)
  const titleSuffix = data.datoCmsSite!.globalSeo!.titleSuffix!

  return titleSuffix
}

const query = graphql`
  query TitleSuffixQuery {
    datoCmsSite {
      globalSeo {
        titleSuffix
      }
    }
  }
`
