import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb'
import { ItemContainer, ItemGrid } from '../components/ItemGrid'
import { SEO } from '../components/SEO'
import { WidthWrapper } from '../components/WidthWrapper'
import { Breadcrumb as BreadcrumbType } from '../config/gatsby-node'
import {
  BrandSubcategoryTemplateQuery,
  BrandSubcategoryTemplateQueryVariables,
} from '../types/graphql'
import { resolvePath } from '../utils/dato'

export type PageContext = BrandSubcategoryTemplateQueryVariables & {
  breadcrumbs: BreadcrumbType[]
}

type Props = {
  data: BrandSubcategoryTemplateQuery
  pageContext: PageContext
}

const BrandSubcategoryTemplate: React.FC<Props> = ({
  data,
  pageContext: { breadcrumbs },
}) => {
  const {
    name,
    brand,
    treeChildren,
    productList,
  } = data.datoCmsBrandSubcategory!

  if (!name || !brand || !brand.name || !brand.slug) {
    return <p>Incomplete data provided. Please check DatoCMS entry.</p>
  }
  const title = name + ' by ' + brand.name
  const metaDescription =
    name +
    ' by ' +
    brand.name +
    '. Get sales and support from PT. Unggul Semesta.'

  return (
    <>
      <SEO title={title} metaDescription={metaDescription} />

      <main>
        <Breadcrumb>
          {breadcrumbs.map(({ text, href }) => (
            <BreadcrumbItem key={href} href={href}>
              {text}
            </BreadcrumbItem>
          ))}
          <BreadcrumbItem>{name}</BreadcrumbItem>
        </Breadcrumb>

        <WidthWrapper className="mt-16">
          <header className="my-16 px-6 max-w-lg mx-auto text-center">
            <h1 className="inline-block pb-2 mb-2 border-b border-gray-300 text-3xl font-bold leading-tight">
              {name}
            </h1>
            <p>{brand.name}</p>
          </header>

          <ItemGrid>
            {/* Display the subcategories */}
            {treeChildren && treeChildren.length > 0
              ? treeChildren.map(subcategory => {
                  if (
                    !brand.slug ||
                    !subcategory ||
                    !subcategory.name ||
                    !subcategory.slug ||
                    !subcategory.image
                  ) {
                    return null
                  }

                  return (
                    <ItemContainer
                      linkTo={resolvePath({
                        kind: 'brandSubcategory',
                        brandSlug: brand.slug,
                        slug: subcategory.slug,
                      })}
                      image={
                        subcategory.image.format === 'svg' ? (
                          <div
                            className="relative h-0"
                            style={{
                              paddingBottom: '100%',
                            }}
                          >
                            <img
                              src={subcategory.image.url!}
                              alt=""
                              className="absolute w-full h-full top-0 left-0"
                            />
                          </div>
                        ) : (
                          <Img
                            fluid={subcategory.image!.fluid as FluidObject}
                            alt=""
                          />
                        )
                      }
                      name={subcategory.name}
                    />
                  )
                })
              : null}

            {/* Display the products */}
            {productList && productList.length > 0
              ? productList.map(product => {
                  if (
                    !product ||
                    !product.name ||
                    !product.slug ||
                    !product.images ||
                    product.images.length === 0 ||
                    !product.images[0] ||
                    !product.brand ||
                    !product.brand.slug
                  ) {
                    return null
                  }

                  return (
                    <ItemContainer
                      linkTo={resolvePath({
                        kind: 'product',
                        brandSlug: product.brand.slug,
                        slug: product.slug,
                      })}
                      image={
                        product.images[0].format === 'svg' ? (
                          <div
                            className="relative h-0"
                            style={{
                              paddingBottom: '100%',
                            }}
                          >
                            <img
                              src={product.images[0].url!}
                              alt=""
                              className="absolute w-full h-full top-0 left-0"
                            />
                          </div>
                        ) : (
                          <Img
                            fluid={product.images[0]!.fluid as FluidObject}
                            alt=""
                          />
                        )
                      }
                      name={product.name}
                    />
                  )
                })
              : null}
          </ItemGrid>
        </WidthWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query BrandSubcategoryTemplateQuery($slug: String!) {
    datoCmsBrandSubcategory(slug: { eq: $slug }) {
      name
      image {
        url
      }
      brand {
        name
        slug
        logo {
          url
        }
      }

      treeChildren {
        name
        slug
        image {
          format
          url

          fluid(
            maxWidth: 300
            maxHeight: 300
            imgixParams: { bg: "0FFF", w: "300", h: "300", fit: "fillmax" }
          ) {
            ...DatoFluid
          }
        }
      }

      productList {
        name
        slug
        images {
          format
          url

          fluid(
            maxWidth: 300
            maxHeight: 300
            imgixParams: { bg: "0FFF", w: "300", h: "300", fit: "fillmax" }
          ) {
            ...DatoFluid
          }
        }

        brand {
          name
          slug
        }
      }
    }
  }
`

export default BrandSubcategoryTemplate
